import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import PhotoModal from './PhotoModal';
import axios from 'axios'


const TopContainer = styled.div`
  width: 100vw;
  position: relative;
  margin-top: 60px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-y: hidden;
`;

const Container = styled.div`
  display: flex;
  // background-color: #F0F8FF;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: hidden;
  align-items: center;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 975px) {
    height: auto;
  }
`;

const PosterContainer = styled.div`
  display: block;
  // background-color: #F0F8FF;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: space-evenly;
  padding-left: 10vw;
  padding-right: 10vw;
  scroll-snap-align: start;
  // @media screen and (max-width: 975px) {
  //   padding-left: 15vw;
  //   padding-right: 15vw;
  //   @media screen and (max-width: 575px) {
      
  //   }
  // }
`;

const WebPoster = styled.img`
  width: 80vw;
  max-height: 70vh;
  
`;

const MobilePoster = styled.img`
  
`;

export const LeftArrow = styled(IoIosArrowBack)`
    font-size: 30px;
    position: absolute;
    color: white;
    @media (hover: hover){
        :hover{
        font-size: 35px;
    }
    }
    
`
const RightArrow = styled(IoIosArrowForward)`
    font-size: 30px;
    position: absolute;
    margin-left: -20px;
    color: white; 
    @media (hover: hover){
       :hover{
        font-size: 35px;
    }
     
    }
    
`
const LeftContainer = styled.div`
    position: absolute;
    height: 50%; 
    display: flex;
    align-items: center;
    z-index: 5;
    display: ${({ arrowLeft })=> (arrowLeft ? 'flex' : 'none')};
    
`
const RightContainer = styled.div`
    position: absolute;
    height: 50%;
    width: 3vw;
    z-index: 5;
    display: flex;
    right: 0;
    align-items: center;
    display: ${({ arrowRight })=> (arrowRight ? 'flex' : 'none')};
`

const ArrowContainer = styled.div`
  position: absolute;
  height: 50%;
  display: flex;
  align-items: center;
  z-index: 5;
  ${({ arrow }) => !arrow && 'display: none;'};
`;

const Title = styled.h3`
    color: white;
    padding-left: 70px;
    padding-top: 50px;
    margin-bottom: -4rem;
    margin-top: 80px;
    font-weight: bold;
`

const PictureSelectionContainer = styled.div`
  display: flex;
  // background-color: #F0F8FF;
  overflow-x: scroll;
  align-items: center;
  padding: 10px 0;
  /* Hide the scrollbar but keep the functionality */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  /* Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &:hover {
    /* Show the scrollbar on hover */
    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Color of the scrollbar thumb */
      border-radius: 3px;
    }
  }
`;

const Thumbnail = styled.img`
  width: 100px;
  height: auto;
  margin-right: 10px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;


const ViewAllButton = styled.button`
  display: block;
  padding: 20px;
  background-color: #F0F8FF;
  margin: 0 auto;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  &:hover{
    color: blue;
  }
`;

const Main = styled.div`
 
  // background-color: #F0F8FF;
  
`;

function Projects() {
  let ref = useRef();
  const [topList, setTopList] = useState([]);
  const [arrowLeft, setArrowLeft] = useState(false);
  const [arrowRight, setArrowRight] = useState(true);
  const [background, setBackground] = useState(true);
  const [works, setWorks] = useState([]);
  useEffect(() => {
    fetchWorks();
  });

  const fetchWorks = async () => {
    try {
      const response = await axios.get('https://api.donerightohd.net/works');
      setWorks(response.data);
      setTopList(works)
    } catch (error) {
      console.error('Error fetching works:', error);
    }
  };
  
  const scroll = scrollPos => {
    ref.current.scrollLeft += scrollPos;
  };

  const scrollL = () => {
    if (ref.current.scrollLeft < ref.current.scrollWidth - window.innerWidth * 2) {
      scroll(window.innerWidth);
      setArrowLeft(true);
      setArrowRight(true);
      setBackground(!background);
    } else {
      scroll(window.innerWidth);
      setArrowRight(false);
      setBackground(!background);
    }
  };

  const scrollR = () => {
    if (ref.current.scrollLeft > window.innerWidth) {
      scroll(-window.innerWidth);
      setBackground(!background);
    } else {
      scroll(-window.innerWidth);
      setBackground(!background);
    }
  };

  const changeScreen = () => {
    return ref.current.scrollLeft;
  };

  const autoScroll = () => {
    if (ref.current.scrollLeft < ref.current.scrollWidth - window.innerWidth) {
      scroll(window.innerWidth);
      setBackground(!background);
    } else {
      scroll(-ref.current.scollWidth);
    }
  };

  const changeArrow = () => {
    if (ref.current.scrollLeft > 0) {
      setArrowLeft(true);
    } else {
      setArrowLeft(false);
      setBackground(true);
    }
    if (ref.current.scrollLeft < ref.current.scrollWidth - (window.innerWidth + 100)) {
      setArrowRight(true);
    } else {
      setArrowRight(false);
    }
  };

  useEffect(() => {
    var timer = setInterval(() => autoScroll(), 5000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, [changeScreen]);

  useEffect(() => {
    ref.current.addEventListener('scroll', changeArrow);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Main>
    <Title id="work">What we have built</Title>
      <TopContainer background={background}>
        <Container ref={ref}>
        <LeftContainer  arrowLeft = {arrowLeft}>
            <LeftArrow onClick = {() => scrollR()}/>
        </LeftContainer>
          {topList.map((top, index) => (
            <PosterContainer key={index}>
              {/* <MobilePoster src={`https://api.donerightohd.net/uploads/${top.mediaUrl}`} alt={`Poster ${index}`} /> */}
              <WebPoster src={`https://api.donerightohd.net/uploads/${top.mediaUrl}`} alt={`Poster ${index}`} />
            </PosterContainer>
          ))}
        <RightContainer arrowRight = {arrowRight}>
            <RightArrow onClick = {() => scrollL()} />
        </RightContainer>
        </Container>
      </TopContainer>
      <PictureSelectionContainer>
        {topList.map((top, index) => (
          <Thumbnail
            key={index}
            src={`https://api.donerightohd.net/uploads/${top.mediaUrl}`}
            alt={`Thumbnail ${index}`}
            onClick={() => ref.current.scrollTo(index * window.innerWidth, 0)}
          />
        ))}
      </PictureSelectionContainer>

      <ViewAllButton onClick={openModal}>View All</ViewAllButton>
      <PhotoModal isOpen={isModalOpen} closeModal={closeModal} photos={topList} />
    </Main>
  );
}

export default Projects;
