import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { IoIosClose } from 'react-icons/io';

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ModalContent = styled.div`
  background-color: black;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
  position: relative;
`;

const CloseButton = styled(IoIosClose)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 35px;
  cursor: pointer;
  color: white;
`;

const PhotoItem = styled.img`
  width: 100%;
  height: auto;
  cursor: pointer;
`;

const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  padding: 20px;
`;

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.95)',
    zIndex: 1000,
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0',
    padding: '0',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const PhotoModal = ({ isOpen, closeModal, photos }) => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);

  const openPhoto = (index) => {
    setSelectedPhotoIndex(index);
  };

  const closePhoto = () => {
    setSelectedPhotoIndex(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Photo Modal"
      ariaHideApp={false}
      style={customModalStyles}
    >
      <ModalContainer>
        <ModalContent>
          <CloseButton onClick={closeModal} />
          {selectedPhotoIndex !== null ? (
            <>
              <PhotoItem
                src={`https://api.donerightohd.net/uploads/${photos[selectedPhotoIndex].mediaUrl}`}
                alt={`Photo ${selectedPhotoIndex}`}
                onClick={closePhoto} // Close single photo view
              />
              <CloseButton onClick={closePhoto} />
            </>
          ) : (
            <PhotoGrid>
              {photos.map((photo, index) => (
                <PhotoItem
                  key={index}
                  src={`https://api.donerightohd.net/uploads/${photo.mediaUrl}`}
                  alt={`Photo ${index}`}
                  onClick={() => openPhoto(index)}
                />
              ))}
            </PhotoGrid>
          )}
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

export default PhotoModal;
