import React from 'react'
import styled from 'styled-components'
import {GiHamburgerMenu} from 'react-icons/gi'
import logo from "../assests/logo.png"

const NavbarContainer = styled.div`
    width: 100vw;
    height: 80px;
    z-index: 5;
    background-color: #0f172a;
    display: flex;
    align-items: center;
    justify-content: space-between;


`
const LogoContainer = styled.div`
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`
const Logo = styled.img`
    height: 50px;
    width: 60px;
    

`
const Title = styled.h2`
    padding-top: 8px;
    padding-left: 5px;
    color: white;
    font-display: block;
    font-family: 'Roboto', sans-serif;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MenuContainer = styled.div`
    padding-right: 30px;
    margin-top: -5px;
    color: white;
`
const Menu =styled(GiHamburgerMenu)`
    
`


function Navbar({toggle}) {
  return (
    <NavbarContainer>
        <LogoContainer>
            <Logo src={logo} alt='logo'/>
            <Title>Done Right Overhead Doors</Title>
        </LogoContainer>
        <MenuContainer onClick = {toggle}>
            <GiHamburgerMenu/>
        </MenuContainer>
    </NavbarContainer>
  )
}

export default Navbar