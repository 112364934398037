import React from 'react'
import styled  from 'styled-components'

const FooterContainer = styled.div`
    width: 100%;
    height: 70px;
    position: relative;
    /* background-color: black; */
    display: flex;
    flex-direction: row;
    align-items: center;
  
`

const FooterText = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

`
const FootText = styled.div`
    font-size: 10px;
    color: white;
`

function Footer() {
  return (
    <FooterContainer>
      <FooterText>
        <FootText>
        &copy; 2023 Done Right Overhead Doors LLC. All rights reserved.
        </FootText>
      </FooterText>
    </FooterContainer>
  )
}

export default Footer