import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Workers from "./components/Workers";
import MediaLinks from "./components/MediaNav";
import styled from 'styled-components'
import React, {useState} from "react";
import NavButton from "./components/NavButton";
import Main from "./components/Main";


function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () =>{
    setIsOpen(!isOpen)
  }
  if (isOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'visible';
  }


  return (
    <>
      <MediaLinks isOpen = {isOpen} toggle = {toggle}/>
      <Main isOpen={isOpen} toggle={toggle}/>
    </>
  );
}

export default App;
