import React from "react"
import emailjs from "emailjs-com";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components"

export const ContactContainer = styled.div` 

    width: 100%;
    min-height: calc(80vh - 70px);
    position: relative;
    /* background-color: #efecff; */
    scroll-snap-align: start;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    


`
export const FormContainer = styled.div`
    display:flex; 
    flex-direction: column;   
    align-items: center;

`
export const FormText = styled.div`


`
export const Text = styled.h3`
    overflow: hidden;
    color: white;
    font-weight: bold;

`
export const FooterContainer = styled.div`
    width: 100%;
    height: 70px;
    position: relative;
    /* background-color: black; */
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const FooterText = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    color: #b0b0b0;

`
export const FootText = styled.div`
    font-size: 10px;
`

export default function Contact () {
    
   function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm("service_dhks6t6","template_i0zshty", e.target, 'Qh-DVkaorE6RGpRgE')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset()
   }
  return (
        <ContactContainer id = "contact">            
            <FormContainer>
            <Text>Contact Us</Text>
            <form onSubmit = {sendEmail}>
            <div className="row pt-5 mx-auto">
                <FormText>
                        <div className="col-15 form-group mx-auto">
                            <input type="text" className="form-control" placeholder="Name" name="user_name"/>
                        </div>
                        <div className="col-15 form-group pt-2 mx-auto">
                            <input type="email" className="form-control" placeholder="Email Address" name="user_email"/>
                        </div>
                        <div className="col-15 form-group pt-2 mx-auto">
                            <textarea className="form-control" id="" cols="10" rows="4" placeholder="Your message" name="message"></textarea>
                        </div>
                </FormText>
                        <div className="col-15 pt-3 mx-auto">
                            <input type="submit" className="btn btn-info" value="Send Message"></input>
                        </div>
                    </div>
            </form>
            </FormContainer>
        </ContactContainer>
        
  );
};