import React from 'react';
import styled from 'styled-components';
import { FaRegClock } from 'react-icons/fa'; // Placeholder clock icon
import logo from "../assests/logo.png"

const TimeSection = styled.div`
  width: 100vw;
  padding: 50px 20px;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
`;

const SectionContent = styled.div`
  flex: 1; /* Allow the content to take available space */
  
  margin-right: 20px;
  padding: 50px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 20px;
  text-decoration: underline;
`;

const SectionText = styled.p`
  text-align: left;
`;


const EveryMomentCounts = () => {
  return (
    <TimeSection>
      <SectionContent>
        <SectionTitle>EVERY MOMENT COUNTS</SectionTitle>
        <SectionText>
            In the realm of time, its value is universally acknowledged through countless proverbs. Time, unyielding and impartial, waits for no one. In the dynamic landscape of the hosting industry, time equates to currency – the currency for business development, customer care, and issue resolution. In this fast-paced environment, customers are reluctant to endure prolonged issue resolution times. A delay in resolution can lead them to seek alternatives promptly.
          <br />
          <br />
            This is where the essence of premium support becomes paramount. With us, you're not just getting support; you're embracing a spectrum of benefits. Our tailored offerings encompass a variety of plans, manned by dedicated L1 – L3 level technicians, ensuring swift responses. The result? A singular focus for you – more business. Rapid business expansion within tight timelines translates to increased revenue. By partnering with us, you can redirect your attention to business growth while we adeptly manage your servers and cater to your customers.
          <br />
          <br />
            Our round-the-clock monitoring services serve as the cornerstone of delivering unparalleled service quality to your customers.
        </SectionText>
      </SectionContent>
    </TimeSection>
  );
};

export default EveryMomentCounts;
