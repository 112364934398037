import About from "./About";
import Contact from "./Contact";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Projects from "./Projects";
import Workers from "./Workers";
import MediaLinks from "./MediaNav";
import styled from 'styled-components'
import React, {useState} from "react";
import NavButton from "./NavButton";
import Yelp from "./Yelp";
import Vendors from "./Vendors";
import EveryMomentCounts from "./EveryMomentCounts";

const Container = styled.div`
    margin: 0;
    overflow: hidden;
    background-color: #0f172a;
    color: black;
    flex-direction: column;
    align-items: center;
    transition: 0.7s ease-in-out;
    opacity: ${({ isOpen })=> (isOpen ? '0.3': '1')};
`

function Main({isOpen, toggle}) {

  return (
    <Container isOpen={isOpen}>
      <Navbar isOpen = {isOpen} toggle = {toggle}/>
      <NavButton isOpen = {isOpen} toggle = {toggle}/>
      <About/>
      <Projects/>
      <Vendors/>
      <Yelp/>
      <Contact/>
      <Footer/>
    </Container>
  );
}

export default Main;
