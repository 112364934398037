import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import ScrollTrigger from 'react-scroll-trigger';
import Modal from 'react-modal';

const Container = styled.div`
  width: 100vw;
  // background-color: #F0F8FF;
  padding: 50px;
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  transform: ${(props) => (props.isVisible ? 'translateY(0)' : 'translateY(20px)')};
  transition: opacity 1s, transform 1s;
`;

const ReviewSection = styled.section`
  max-width: 800px;
  margin: 0 auto;
`;

const ReviewTitle = styled.h3`
  text-align: center;
  margin-bottom: 20px;
  color: white;
  margin-top: 20px;
  font-weight: bold;
`;

const Review = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
`;

const ReviewText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

const StarContainer = styled.div`
  text-align: center;
`;

const StarRating = styled.div`
  font-size: 24px;
  color: #ffbf00;
  display: inline-block;
  animation: bounceAndSpin 3s infinite;
  animation-delay: ${(props) => props.delay}s;

  @keyframes bounceAndSpin {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px) rotate(360deg);
    }
    60% {
      transform: translateY(-10px);
    }
  }
`;

const PlaceholderReview = styled.div`
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #888;
`;

const ReviewHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
  color: white;
`;

const Username = styled.span`
  font-weight: bold;
`;

const DatePosted = styled.span`
  margin-left: 10px;
  color: #888;
`;

const CreateReviewLink = styled.a`
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  &:hover{
    
  }
`;

const WriteReviewButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ViewAllReviewsButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ReviewModal = styled(Modal)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0f172a;
  color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  max-width: 1000px;
  width: 80%;
  text-align: center;

  &:focus {
    outline: none;
  }
`;

const ModalTitle = styled.h2`
  color: white;
  margin-bottom: 20px;
`;

const ModalContent = styled.div`
  margin-top: 20px;
`;

const ModalCloseButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ReviewForm = styled.form`
  text-align: left;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 8px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
`;

const StyledReview = styled(Review)`
  background-color: white;
  color: black;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
`;

const StyledReviewText = styled(ReviewText)`
  font-size: 16px;
  margin-bottom: 10px;
`;

const StyledStarContainer = styled(StarContainer)`
  text-align: center;
`;

const StyledStarRating = styled(StarRating)`
  font-size: 24px;
  color: #ffbf00;
  display: inline-block;
  animation: bounceAndSpin 3s infinite;
  animation-delay: ${(props) => props.delay}s;

  @keyframes bounceAndSpin {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px) rotate(360deg);
    }
    60% {
      transform: translateY(-10px);
    }
  }
`;

const StyledReviewModal = styled(ReviewModal)`
  max-height: 90vh; /* Adjust the maximum height as needed */
  overflow-y: auto;
  border-radius: 15px;
`;

function Yelp() {
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [writeReviewModalVisible, setWriteReviewModalVisible] = useState(false);
  const [viewAllReviewsModalVisible, setViewAllReviewsModalVisible] = useState(false);

  const [newReview, setNewReview] = useState({
    userName: '',
    rating: 5, // Default rating
    reviewText: '',
  });

  useEffect(() => {
    axios.get("https://api.donerightohd.net/reviews")
      .then(response => {
        setReviews(response.data);
        calculateAverageRating(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const calculateAverageRating = reviews => {
    if (reviews.length === 0) {
      setAverageRating(0);
      return;
    }

    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    const avgRating = totalRating / reviews.length;
    setAverageRating(avgRating);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewReview((prevReview) => ({ ...prevReview, [name]: value }));
  };

  const submitReview = async (event) => {
    event.preventDefault();
  
    try {
      const response = await axios.post("https://api.donerightohd.net/reviews", {
        user: {
          name: newReview.userName,
        },
        time_created: new Date().toISOString(),
        text: newReview.reviewText,
        rating: parseInt(newReview.rating),
      });
  
      console.log('Review submitted successfully:', response.data);
  
      // Close the write review modal
      setWriteReviewModalVisible(false);
  
      // Optionally, you can fetch updated reviews after submitting the new review
      const updatedResponse = await axios.get("https://api.donerightohd.net/reviews");
      setReviews(updatedResponse.data);
      calculateAverageRating(updatedResponse.data);
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  const writeReviewClickHandler = () => {
    setWriteReviewModalVisible(true);
  };

  const viewAllReviewsClickHandler = () => {
    setViewAllReviewsModalVisible(true);
  };

  return (
    <ScrollTrigger
      onEnter={() => setIsVisible(true)}
    >
      <Container id="reviews" isVisible={isVisible}>
        <ReviewSection>
          <ReviewTitle>Reviews</ReviewTitle>
          <ReviewHeader>
            <p>Average Rating: {averageRating.toFixed(2)} stars</p>
          </ReviewHeader>
          {reviews.slice(0, 5).map(review => (
            <Review key={review.id}>
              <Username>{review.user.name || 'Anonymous'}</Username>
              <DatePosted>{new Date(review.time_created).toLocaleDateString()}</DatePosted>
              <ReviewText>{review.text}</ReviewText>
              <StarContainer>
                {[...Array(review.rating)].map((_, index) => (
                  <StarRating key={index} delay={index * 0.1}>
                    ★
                  </StarRating>
                ))}
              </StarContainer>
            </Review>
          ))}
          {reviews.length > 5 && (
            <ViewAllReviewsButton onClick={viewAllReviewsClickHandler}>
              View All Reviews
            </ViewAllReviewsButton>
          )}
          <WriteReviewButton onClick={writeReviewClickHandler}>
            Write a Review
          </WriteReviewButton>
        </ReviewSection>
      </Container>

      <ReviewModal
        isOpen={writeReviewModalVisible}
        onRequestClose={() => setWriteReviewModalVisible(false)}
        contentLabel="Write a Review"
      >
        <ModalTitle>Write a Review</ModalTitle>
        <ModalContent>
          <ReviewForm onSubmit={submitReview}>
            <FormLabel>Name:</FormLabel>
            <FormInput
              type="text"
              name="userName"
              value={newReview.userName}
              onChange={handleInputChange}
              required
            />
            <FormLabel>Review:</FormLabel>
            <FormInput
              type="text"
              name="reviewText"
              value={newReview.reviewText}
              onChange={handleInputChange}
              required
            />
            <FormLabel>Rating:</FormLabel>
            <FormInput
              type="number"
              name="rating"
              value={newReview.rating}
              onChange={handleInputChange}
              min="1"
              max="5"
              required
            />
            <button type="submit">Submit Review</button>
          </ReviewForm>
          <ModalCloseButton onClick={() => setWriteReviewModalVisible(false)}>
            Close
          </ModalCloseButton>
        </ModalContent>
      </ReviewModal>

      <StyledReviewModal
    isOpen={viewAllReviewsModalVisible}
    onRequestClose={() => setViewAllReviewsModalVisible(false)}
    contentLabel="View All Reviews"
  >
    <ModalTitle>View All Reviews</ModalTitle>
    <ModalContent>
      {reviews.map(review => (
        <StyledReview key={review.id}>
          <Username>{review.user.name || 'Anonymous'}</Username>
          <DatePosted>{new Date(review.time_created).toLocaleDateString()}</DatePosted>
          <StyledReviewText>{review.text}</StyledReviewText>
          <StyledStarContainer>
            {[...Array(review.rating)].map((_, index) => (
              <StyledStarRating key={index} delay={index * 0.1}>
                ★
              </StyledStarRating>
            ))}
          </StyledStarContainer>
        </StyledReview>
      ))}
    </ModalContent>
    <ModalCloseButton onClick={() => setViewAllReviewsModalVisible(false)}>
      Close
    </ModalCloseButton>
  </StyledReviewModal>
    </ScrollTrigger>
  );
}

export default Yelp;
