import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import door from '../assests/IMG_20211218_175502.jpg';
import { TbCircleDotted } from "react-icons/tb";
import { FaCircle, FaFacebook, FaYelp } from 'react-icons/fa';
import { MdOutlineBusiness } from 'react-icons/md';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #0f172a;
`;

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  min-height: 400px;
  position: relative;
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  transform: ${(props) => (props.isVisible ? 'translateY(0)' : 'translateY(20px)')};
  transition: opacity 1s, transform 1s;
  z-index: 1;
`;

const CallToAction = styled.div`
  position: absolute;
  top: 60px;
  left: 50px;
  color: white;
  padding: 20px;
  font-size: 2em;
  max-width: 1000px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-shadow: 4px 4px 8px black;
  @media screen and (max-width: 500px) {
    font-size: 25px;
    top: 20px;
    left: 20px;
  }
  @media screen and (max-height: 500px) {
    max-width: 1000px;
    top: 5px;
  }
`;

const ImgBG = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AboutTitle = styled.h3`
  padding-left: 50px;
  margin-top: 30px;
  margin-right: 20px;
  font-weight: bold;
  color: white;
`;

const AboutParagraph = styled.p`
  padding: 50px;
  position: relative;
  color: white;
`;

const Image = styled.img`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  filter: brightness(0.5);
  @media screen and (max-width: 768px) {
    object-position: left;
  }
`;

const SocialIconsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50px;
  display: flex;
  gap: 10px;
`;

const SocialIcon = styled.a`
  color: white;
  font-size: 24px;
  text-decoration: none;
  &:hover {
    color: blue;
  }
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingIcon = styled(TbCircleDotted)`
  font-size: 150px;
  color: #052c65;
  position: absolute;
  top: 80%;
  left: 70%;
  transform: translate(-50%, -50%) rotate(0deg);
  animation: ${rotateAnimation} 5s linear infinite;
`;

const TextInCircle = styled.div`
  font-size: 15px;
  color: #052c65;
  position: absolute;
  top: 95%;
  left: 79%;
  transform: translate(-50%, -50%) rotate(0deg);
`;

function About() {
  const [aboutContent, setAboutContent] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.donerightohd.net/about');
        setAboutContent(response.data.content);
        setIsVisible(true);
      } catch (error) {
        console.error('Error fetching about content:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container id="about">
      <Image src={door} />
      <CallToAction>
        Garage Doors Done Right: Where Precision Meets Passion, Excellence is Our Tradition!
      </CallToAction>
      <AboutContainer isVisible={isVisible}>
        <AboutTitle>Done Right Overhead Doors</AboutTitle>
        <AboutParagraph>{aboutContent}</AboutParagraph>
        <SocialIconsContainer>
          <SocialIcon href="https://facebook.com/donerightoverheaddoor" target="_blank">
            <FaFacebook />
          </SocialIcon>
          <SocialIcon
            href="https://www.yelp.com/biz/done-right-overhead-doors-las-vegas-2?utm_campaign=www_business_share_popup&utm_medium=copy_link&utm_source=(direct)"
            target="_blank"
          >
            <FaYelp />
          </SocialIcon>
          <SocialIcon
            href="https://www.bbb.org/us/nv/las-vegas/profile/garage-door-repair/done-right-overhead-doors-llc-1086-90084751/#sealclick"
            target="_blank"
          >
            <MdOutlineBusiness />
          </SocialIcon>
        </SocialIconsContainer>
      </AboutContainer>
    </Container>
  );
}

export default About;
