import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaExternalLinkAlt } from 'react-icons/fa';
import ScrollTrigger from 'react-scroll-trigger'; // Import the ScrollTrigger component

const VendorsContainer = styled.div`
  width: 100vw;
  margin-bottom: 50px;
  padding: 30px;
  display: flex;
  flex-direction: column; /* Updated to column layout */
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  transform: ${(props) => (props.isVisible ? 'translateY(0)' : 'translateY(20px)')};
  transition: opacity 1s, transform 1s;
`;

const Title = styled.h2`
  color: white;
  margin-bottom: 20px;
  font-weight: bold;
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  width: 600px;
  height: 500px;
  @media screen and (max-width: 600px) {
    width: 90vw;
  }
`;

const Box = styled.div`
  width: 100%;
  padding: 20px;
  border: solid;
  // border-width: thick;
  border-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  background-color: ${(props) => props.backgroundColor || '#0f172a'};
  @media screen and (max-width: 1000px) {
    // height: 300px;
  }
`;

const ProjectTitle = styled.h4`
  padding: 10px;
  color: white;
`;

const ProjectDescription = styled.p`
  padding: 10px;
  color: white;
`;

const ProjectIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

const ProjectIcon = styled.div`
  font-size: 5em;
  padding: 30px;
`;

const LinkIcon = styled.a`
  font-size: 1em;
  margin-bottom: 10px;
  color: white;
  text-decoration: none;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
`;

const Vendors = () => {
  const [vendors, setVendors] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    fetchVendors();
  }, []);

  const fetchVendors = async () => {
    try {
      const response = await axios.get('https://api.donerightohd.net/vendors');
      setVendors(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  return (
    <ScrollTrigger
      onEnter={() => setIsVisible(true)} // Set isVisible to true when the component enters the view
    >
      <VendorsContainer id="vendors" isVisible={isVisible}>
        <Title>Vendors</Title>
        {vendors.map((project, index) => (
          <BoxContainer key={index}>
            <Box backgroundColor={project.color}>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectDescription>{project.description}</ProjectDescription>
              <ProjectIconContainer>
                <LinkIcon href={project.link} target="_blank" rel="noopener noreferrer">
                  <FaExternalLinkAlt />
                </LinkIcon>
              </ProjectIconContainer>
            </Box>
          </BoxContainer>
        ))}
      </VendorsContainer>
    </ScrollTrigger>
  );
};

export default Vendors;
