import React from 'react'
import styled from 'styled-components'
import {GoLinkExternal} from 'react-icons/go'
import Profile from '../assests/IMG_3342.jpg'

const WorkersContainer = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;


`

const Title = styled.h3`
    padding: 50px


`

const Container = styled.div`
    align-items: center;
    width: 100vw;
    display: flex;
    flex-direction: column;

`

const WorkerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 30px;
    align-items: center;
    max-width: 1100px;
    

`
const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    


`
const Description = styled.p`
    
`
const Link = styled(GoLinkExternal)`

`
const WorkerPic = styled.img`
    width: 300px;
    height: 300px;
    border-radius: 300px;
    padding: 10px;


`

function Workers() {
  return (
    <WorkersContainer>
        <Title>Meet our Team</Title>
        <Container>
          <WorkerContainer>
            <DescriptionContainer>
              <Description>Dylan McDowell, the IT Specialist and Owner at RedDawg Tech. A USMC Veteran and seasoned Systems Engineer, Dylan brings a unique blend of skills to the table. With expertise in full stack web development and server management, he's the mastermind behind our innovative solutions. From crafting seamless web applications to optimizing server performance, Dylan's commitment to excellence drives RedDawg Tech's success.</Description>
              <Link/>
            </DescriptionContainer>
            <WorkerPic src={Profile} alt="picture"/>
          </WorkerContainer>
        </Container>
    </WorkersContainer>
  )
}

export default Workers