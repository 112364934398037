import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {GiHamburgerMenu} from 'react-icons/gi'

const FixedItem = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 40px;
  z-index: 5;
  height: 40px;
  border-radius: 50px;
  top: 20px;
  right: 20px;
  color: white;
  background-color: #001F3F;
  padding: 10px;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out;
  opacity: ${props => (props.show ? 1 : 0)};
`;

const NavButton = ({toggle}) => {
  const [showFixedItem, setShowFixedItem] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setShowFixedItem(true);
      } else {
        setShowFixedItem(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <FixedItem show={showFixedItem} onClick = {toggle}>
        <GiHamburgerMenu/>
      </FixedItem>
    </>
  );
};

export default NavButton;
